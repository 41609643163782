import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { formatImageUrl } from "../../../helper/helper";
const ImageSlider = (props: any) => {
  const { data } = props;
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
  };
  return (
    <div className="image-sliderContainer">
      <Slider {...settings}>
        {data?.items?.length > 0
          ? data?.items?.map((item: any, index: any) => {
              let bgImage: any = formatImageUrl(
                item?.background?.data?.attributes?.url
              );
              let bottle_image: any = formatImageUrl(
                item?.bottle_image?.data?.attributes?.url
              );
              let shadow_image: any = formatImageUrl(
                item?.shadow_image?.data?.attributes?.url
              );
              return (
                <div
                  tabIndex={index}
                  key={index}
                  className="slider-card"
                  style={{ backgroundImage: `url(${bgImage})` }}
                >
                  {item?.background?.data && (
                    <img src={bgImage} alt="" className="card-bg" />
                  )}
                  {item?.bottle_image?.data && (
                    <img src={bottle_image} className="bottle" />
                  )}
                  {item?.shadow_image?.data && (
                    <img src={shadow_image} className="bottle-shadow" />
                  )}
                </div>
              );
            })
          : null}
      </Slider>
    </div>
  );
};

export default ImageSlider;

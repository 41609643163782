import Image from "next/image";
import React from "react";
import { formatImageUrl } from "../../../helper/helper";

const AwardsBanner = (props: any) => {
  let desktopImage: any = formatImageUrl(
    props?.data?.background?.data?.attributes?.url
  );
  let mobileImage: any = formatImageUrl(
    props?.data?.background?.data?.attributes?.url
  );
  return (
    <section className="awards_section">
      <img
        src={desktopImage}
        alt={`awards img`}
        width={1440}
        height={700}
        className="award_img for_desktop"
      />
      <img
        src={mobileImage}
        alt={`awards img`}
        width={393}
        height={347}
        className="award_img for_mobile"
      />

      <div className="awards_inner_section">
        <h2>{props?.data?.title}</h2>
        <h3>{props?.data?.sub_title}</h3>
        <img
          src="/single-cask/down-scroll-arrow.png"
          alt={`scroll arrow`}
          width={100}
          height={100}
          className="scroll_arrow"
        />
      </div>
    </section>
  );
};

export default AwardsBanner;

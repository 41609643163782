"use client";

import { useLocalsListStore } from "@/zustand/localesList";
import Link from "next/link";
import { formatImageUrl, getCookie } from "../../../../helper/helper";

const GinCollectionDetail = (props: any) => {
  const { ginData, data } = props;
  const localesList = useLocalsListStore((state: any) => state.localesList);
  const locale = localesList.locale || getCookie("locale");

  let bottle_bg: any = formatImageUrl(
    data?.bottle_background?.data?.attributes?.url
  );
  let bottle_image: any = formatImageUrl(
    data?.bottle_image?.data?.attributes?.url
  );
  return (
    <div className="gin_section">
      {data && (
        <div className="gin_collection_section">
          <div className="gin_inner_div">
            <div className="gin_detail_section">
              <div className="inner_details">
                <h3>{data?.title ? data?.title : ""}</h3>
                <div className="years_details">
                  <h4>{data?.year ? data?.year : ""}</h4>
                  <h4>{data?.year_details ? data?.year_details : ""}</h4>
                </div>

                <p>{data?.content ? data?.content : ""}</p>
                {data?.cta?.link && (
                  <Link
                    href={data?.cta?.link}
                    className="read_more_gin"
                    target={
                      data?.cta?.open_in_new_tab == true ? "_blank" : "_self"
                    }
                  >
                    {data?.cta?.title}
                  </Link>
                )}

                <div className="award_section">
                  <h2>{data?.award_title}</h2>
                  {data?.award_images?.data && (
                    <div className="awards_img">
                      {data?.award_images?.data?.map(
                        (item: any, index: any) => {
                          let award_image: any = formatImageUrl(
                            item?.attributes?.url
                          );
                          return (
                            <img
                              src={award_image}
                              alt="award image"
                              key={index}
                            />
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {data?.bottle_background?.data && data?.bottle_image?.data ? (
              <div
                className="gin_image_section"
                style={{ backgroundImage: `url(${bottle_bg})` }}
              >
                <img
                  src={bottle_image}
                  alt="bottle image"
                  className="bottle_img"
                />
              </div>
            ) : null}
          </div>
          <div className="nots_section">
            {data?.items?.map((item: any, index: any) => {
              return (
                <div className="nots_details" key={index}>
                  <h2>{item?.title}</h2>
                  <p>{item?.content ? item?.content : ""}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* {ginData?.collectionData.length > 0 &&
        ginData?.collectionData.map((item: any, index: number) => {
          return (
            <div className="gin_collection_section" key={index}>
              <div className="gin_inner_div">
                <div className="gin_detail_section">
                  <div className="inner_details">
                    <h3>{item?.title ? item?.title : ""}</h3>

                    <div className="years_details">
                      <h4>{item?.year ? item?.year : ""}</h4>
                      <h4>{item?.content ? item?.content : ""}</h4>
                    </div>

                    <p>{item?.description ? item?.description : ""}</p>
                    <Link
                      href={`/${locale}${item?.Link}`}
                      className="read_more_gin"
                    >
                      learn more
                    </Link>

                    <div className="award_section">
                      <h2>AWARDS</h2>
                      <div className="awards_img">
                        <img src="/product/award-one.jpg" alt="award image" />
                        <img src="/product/award-two.jpg" alt="award image" />
                        <img src="/product/award-one.jpg" alt="award image" />
                        <img src="/product/award-two.jpg" alt="award image" />
                      </div>
                    </div>
                  </div>
                </div>
                {item?.bgURL && data?.bottleImage ? (
                  <div
                    className="gin_image_section"
                    style={{ backgroundImage: `url(${item?.bgURL})` }}
                  >
                    <img
                      src={item?.bottleImage}
                      alt="bottle image"
                      className="bottle_img"
                    />
                  </div>
                ) : null}
              </div>
              <div className="nots_section">
                <div className="nots_details">
                  <h2>Nose</h2>
                  <p>{item?.nose ? item?.nose : ""}</p>
                </div>
                <div className="nots_details center_nots">
                  <h2>Palate</h2>
                  <p>{item?.taste ? item?.taste : ""}</p>
                </div>
                <div className="nots_details last_nots">
                  <h2>finish</h2>
                  <p>{item?.serve ? item?.serve : ""}</p>
                </div>
              </div>
            </div>
          );
        })} */}
    </div>
  );
};

export default GinCollectionDetail;

"use client";
import BuyNowCart from "@/components/common/BuyNowCart";
import MainLayout from "@/components/layout/mainLayout";
import ScoMeta from "@/components/scometa";
import AwardsBanner from "@/components/singlecask/AwardsBanner";
import SingleCaskAwardsSection from "@/components/singlecask/SingleCaskAwardsSection";
import {
  getCheckoutLineItems,
  setRemoveLineItemInCart,
  setUpdateQuantityInCart,
} from "@/util/utils";
import { useEffect, useState } from "react";

const OurAwards = (props: any) => {
  const { data, meta, countryCode } = props;

  const [ourAwardData, setOurAwardData]: any = useState(null);
  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [openModal, setOpenModal]: any = useState(false);

  const toggleCartSidebar = () => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });

    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };
  useEffect(() => {
    if (data) {
      setOurAwardData(data?.data?.attributes?.blocks);
    }
  }, [data]);
  const renderOurAwardPage = () => {
    return (
      <>
        {ourAwardData && ourAwardData.length > 0
          ? ourAwardData.map((content: any, index: any) => {
              switch (content.__component) {
                case "awards.banner":
                  return (
                    <div key={index}>
                      <AwardsBanner data={content} />
                    </div>
                  );

                case "awards.award-table":
                  return (
                    <SingleCaskAwardsSection
                      key={content?.id + index}
                      data={content}
                    />
                  );

                default:
                  return null;
              }
            })
          : null}
      </>
    );
  };
  return (
    <>
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      <MainLayout checkout={checkout} toggleCartSidebar={toggleCartSidebar}>
        <div>{ourAwardData && renderOurAwardPage()}</div>
      </MainLayout>
    </>
  );
};

export default OurAwards;

"use client";
import MainLayout from "@/components/layout/mainLayout";
import { useLocalsListStore } from "@/zustand/localesList";
import React, { useEffect, useState } from "react";
import { getCookie } from "../../../helper/helper";
import api from "../../../api";
import SignUpForFreeSection from "@/components/cards/signUpForFreeSection";
import ImageSlider from "@/components/indainSummergin/imageSlider";
import Whiskywanderland from "@/components/indainSummergin/whiskywanderland";
import GinCollectionDetail from "@/components/common/GinCollectionDetail";
import BuyNowCart from "@/components/common/BuyNowCart";
import {
  getCheckoutLineItems,
  setRemoveLineItemInCart,
  setUpdateQuantityInCart,
} from "@/util/utils";
import GinDetailsSection from "@/components/indainSummergin/ginDetailsSection";
import ScoMeta from "@/components/scometa";

const IndainSummerGinpage = (props: any) => {
  const { data, meta, countryCode } = props;
  const [templateData, setTemplateData]: any = useState([]);
  const [indainsummerginData, setIndiansummerginData]: any = useState(null);

  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [openModal, setOpenModal]: any = useState(false);

  const localesList: any = useLocalsListStore(
    (state: any) => state.localesList
  );
  const locale = localesList.locale || getCookie("locale") || "en-US";
  const getMasterTemplateData = (props: any) => {
    api
      .getMasterTemplate(props)
      .then((res: any) => {
        if (res?.data) {
          setTemplateData(res.data.attributes?.newsletter[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (locale) {
      getMasterTemplateData(locale);
    }
  }, [locale]);

  useEffect(() => {
    if (data) {
      setIndiansummerginData(data?.data?.attributes?.blocks);
    }
  }, [data]);

  const ginData: any = {
    title: "Our gin collection",
    subTitle:
      "Duncan Taylor launched its first gin, the saffron infused Indian Summer, in 2014. Applying our years of cask maturation knowledge and experience, the first bottling of our Cask Strength Gins were released in 2018.",
    collectionData: [
      {
        title: "Indian summer saffrom infused gin",
        // year: "18 years old",
        content: "46% Alc./Vol.  |  700ml",
        description:
          "Remember those long, hot, balmy days, where we were in the company of good people, in great surroundings, just enjoying the moment, thinking about nothing else? In today’s world there are not enough of those moments, so we have set out to bring the good times back.",
        Link: "/indian-summer-saffron-gin",
        bgURL: "/product/bg-mark.png",
        bottleImage: "/product/saffron-bottle.png",
        nose: "A soft and well balanced nose of citrus orange, coriander, lemon peel and saffron.",
        taste:
          "Smooth and sweet with spiced orange peel, coriander, pink peppercorns and lemon zest wrapped in a blanket of saffron.",
        serve: "Rich and warming.",
      },
      {
        title: "Indian summer cask strength gin",
        // year: "6 years old",
        content: "52% Alc./Vol.  |  700ml",
        description:
          "Remember those long, hot, balmy days, where we were in the company of good people, in great surroundings, just enjoying the moment, thinking about nothing else? In today’s world there are not enough of those moments, so we have set out to bring the good times back.",
        Link: "/indian-summer-cask-strength-gin",
        bgURL: "/product/bg-mark.png",
        bottleImage: "/product/cask-bottle.png",
        nose: "Nutty, woody notes, ginger, juniper and herbal notes coming through.",
        taste:
          "Woody, earthy and spicy, with notes of juniper; quite oily on the palate.",
        serve: "Relatively short with spicy notes lingering around.",
      },
    ],
  };

  const toggleCartSidebar = () => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });

    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const renderIndianSummerGinPage = () => {
    return (
      <>
        {indainsummerginData && indainsummerginData.length
          ? indainsummerginData.map((content: any, index: any) => {
              switch (content.__component) {
                case "summer-gin.banner":
                  return (
                    <div key={index}>
                      <ImageSlider data={content} />
                    </div>
                  );

                case "summer-gin.our-gin":
                  return (
                    <GinDetailsSection
                      key={content?.id + index}
                      data={content}
                    />
                  );
                case "summer-gin.indian-collection":
                  return (
                    <GinCollectionDetail
                      key={content?.id + index}
                      data={content}
                    />
                  );
                case "summer-gin.indian-caskstrength":
                  return (
                    <GinCollectionDetail
                      key={content?.id + index}
                      data={content}
                    />
                  );

                default:
                  return null;
              }
            })
          : null}
      </>
    );
  };

  return (
    <>
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      <MainLayout checkout={checkout} toggleCartSidebar={toggleCartSidebar}>
        <div className="gin-container">
          {indainsummerginData && renderIndianSummerGinPage()}
        </div>
        <SignUpForFreeSection templateData={templateData} />
      </MainLayout>
    </>
  );
};

export default IndainSummerGinpage;

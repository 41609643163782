"use client";
import BuyNowCart from "@/components/common/BuyNowCart";
import Galary from "@/components/common/galaryslider";
import BannerVideo from "@/components/common/video";
import SignupForFree from "@/components/landingpage/SignupForFree";
import NewMainLayout from "@/components/layout/newMainLayout";
import NewCollectionBanner from "@/components/newoctavecollection/NewCollectionBanner";
import AboutTheRarest from "@/components/rarestcollection/AboutTheRarest";
import Kinclaith from "@/components/rarestcollection/Kinclaith";
import PortEllen from "@/components/rarestcollection/PortEllen";
import ScoMeta from "@/components/scometa";
import {
  getCheckoutLineItems,
  setRemoveLineItemInCart,
  setUpdateQuantityInCart,
} from "@/util/utils";
import React, { useEffect, useState } from "react";

const Rarestcollection = (props: any) => {
  const { data, meta, countryCode } = props;

  const [rarestcollectionData, setRarestcollectionData]: any = useState(null);
  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [openModal, setOpenModal]: any = React.useState(false);

  useEffect(() => {
    if (data) {
      setRarestcollectionData(data.data.attributes.blocks);
    }
  }, [data]);
  const renderRarestCollectionPage = () => {
    return (
      <>
        {rarestcollectionData && rarestcollectionData.length
          ? rarestcollectionData.map((content: any, index: any) => {
              switch (content.__component) {
                case "sections.banner":
                  // let videosrc = content.video_link;
                  return (
                    <div key={index}>
                      <NewCollectionBanner
                        data={content}
                        className="small_logo_for_banner top_paddings rarest_text"
                        mainBottleImg="rarest_banner_bottle"
                      />
                    </div>
                  );

                case "rarest.about-the-rarest":
                  return (
                    <AboutTheRarest key={content?.id + index} data={content} />
                  );

                // case "rarest.about-the-rarest-2":
                //   return <PortEllen key={content?.id + index} data={content} />;

                case "sections.gallery":
                  return <Galary data={content} key={content?.id + index} />;

                case "rarest.discover-more":
                  return (
                    <Kinclaith
                      bottleimgurl={"rarestcollection/taylor1969.png"}
                      key={content?.id + index}
                      data={content}
                      isDisplay
                    />
                  );

                default:
                  return null;
              }
            })
          : null}
      </>
    );
  };

  const toggleCartSidebar = () => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });

    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  return (
    <>
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      {rarestcollectionData && (
        <NewMainLayout
          checkout={checkout}
          toggleCartSidebar={toggleCartSidebar}
        >
          {rarestcollectionData && renderRarestCollectionPage()}
          <div className="rarest-collection-signupforfree">
            <SignupForFree />
          </div>
        </NewMainLayout>
      )}
    </>
  );
};

export default Rarestcollection;

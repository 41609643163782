import Image from "next/image";
import React, { useEffect } from "react";
import { formatImageUrl } from "../../../helper/helper";

const getYearsList = (data: any) => {
  let years: any = [];
  var arr = data.map(function (el: any) {
    return el.attributes.year_number;
  });
  years = arr.sort();
  return years;
};

const getAwardCategories = (data: any, year: any, categoryName: any) => {
  let awards: any = [];
  let awardsList: any = [];
  awards = awards.concat(data.filter((el: any) => el.year == year));
  if (awards?.length !== 0) {
    awards = awards[0].award_categories?.data?.map(
      (item: any, index: number) => {
        awards[0].award_lists?.data?.map((el: any) => {
          if (
            el.attributes.award_categories?.data[0]?.attributes?.name ==
            (categoryName ||
              el.attributes.award_categories?.data[0]?.attributes?.name)
          ) {
            awardsList.push(el.attributes);
          }
        });
        return item.attributes;
      }
    );
  }
  return { awardCategories: awards, awardsList: awardsList };
};

const SingleCaskAwardsSection: any = (props: any) => {
  const [selectedYear, setSelectedYear]: any = React.useState(
    new Date().getFullYear()
  );
  const [categoryName, setCategoryName]: any = React.useState("");
  const [awardCategories, setAwardCategories] = React.useState<any>([]);
  const [awardLists, setAwardLists] = React.useState([]);
  const [selectedAward, setSelectedAward]: any = React.useState(0);
  const [selectedTab, setSelectedTab]: any = React.useState(null);

  const listRef = React.useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (listRef && selectedYear) {
      if (listRef.current) {
        listRef.current.scrollLeft = listRef.current.scrollWidth;
      }
    }
  }, [listRef, selectedYear]);

  useEffect(() => {
    let obj = getAwardCategories(
      props?.data?.items,
      selectedYear,
      categoryName
    );
    setAwardCategories(obj?.awardCategories);
    setAwardLists(obj?.awardsList);
  }, [selectedYear, categoryName]);


  return (
    <section className="single_cask_awards_section">
      <div className="collection_container">
        <div className="awards_details">
          <div className="awards_details_inner_div">
            <ul>
              {awardCategories.map((item: any, index: number) => {
                let img = formatImageUrl(item?.media?.data?.attributes?.url);
                return (
                  <li
                    className={`${selectedAward === index ? "selected" : ""}`}
                    onClick={() => {
                      setSelectedAward(index);
                      setCategoryName(item?.name);
                    }}
                  >
                    <div className="award_img_div">
                      <Image
                        src={img}
                        alt={`awards img`}
                        width={60}
                        height={60}
                        className="indecater_img"
                      />
                    </div>
                    <h2>{item?.name}</h2>
                  </li>
                );
              })}
            </ul>

            <div className="awards_full_details_section">
              <h3 className="title_of_award">
                {categoryName || awardCategories[0]?.name}
              </h3>
              <div className="award_scroll_div">
                <table>
                  {awardLists?.map((item: any, index: number) => {
                    let img = formatImageUrl(
                      item?.bottle_image?.data?.attributes?.url
                    );
                    return (
                      <tr
                        key={index}
                        className={`${
                          selectedTab === index ? "selected_row" : ""
                        }`}
                      >
                        <td>
                          <h2>{item?.title}</h2>
                          <h3 className="for_mobile">{item?.sub_title}</h3>
                        </td>
                        <td className="name_td desktop">
                          <h3>{item?.sub_title}</h3>
                        </td>
                        <td className="for_mobile">
                          <div className="img_of_bottle">
                            {selectedTab === index ? (
                              <Image
                                src={img}
                                alt={`bottle img`}
                                width={43}
                                height={120}
                                className="bottle_img"
                              />
                            ) : null}
                          </div>
                        </td>
                        <td>
                          <h4>{item?.score}</h4>
                        </td>
                        <td className="for_desktop">
                          <div className="img_of_bottle">
                            {selectedTab === index ? (
                              <Image
                                src={img}
                                alt={`bottle img`}
                                width={43}
                                height={120}
                                className="bottle_img"
                              />
                            ) : null}
                          </div>
                        </td>
                        <td>
                          <div className="arrows_div">
                            {selectedTab === index ? (
                              <Image
                                src={"/single-cask/up-arrow.png"}
                                alt={`awards img`}
                                width={24}
                                height={24}
                                className="up-arrow"
                                onClick={() => setSelectedTab(null)}
                              />
                            ) : (
                              <Image
                                src={"/single-cask/down-arrow.png"}
                                alt={`awards img`}
                                width={24}
                                height={24}
                                className="down-arrow"
                                onClick={() => setSelectedTab(index)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="years_section">
        <div className="years_div">
          <ul ref={listRef}>
            {getYearsList(props?.data?.year_lists?.data).map(
              (item: any, index: number) => {
                return (
                  <li
                    key={index}
                    className={`${selectedYear == item ? "active" : ""}`}
                    onClick={() => {
                      setSelectedYear(item);
                      setCategoryName("");
                      setSelectedAward(0);
                    }}
                    id={`year-center-${item}`}
                  >
                    {item}
                    {selectedYear == item ? (
                      <Image
                        src={"/single-cask/selected-year-indecater.png"}
                        alt={`indecater`}
                        width={32}
                        height={32}
                        className="indecater_img"
                      />
                    ) : null}
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SingleCaskAwardsSection;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../common/CommonButton/Button";
import Link from "next/link";

const AboutTheRarest = (props: any) => {
  const { data } = props;

  return (
    <div className="aboutthe-rarest-container">
      <Container>
        <Row>
          <Col md={6} xs={12}>
            <div className="left-outerwrapper">
              <div className="left-innerwrapper">
                <h2 className="headingText">{data?.title}</h2>
                <p className="description">{data?.content}</p>
                <div className="number-container">
                  <span>{data?.number}</span>
                </div>
                {data?.cta?.link ? (
                  <Button
                    btnText={data?.cta?.title}
                    btnClass={"learnmorebtn onlyDesktop"}
                    btnLink={data?.cta?.link}
                    linkOpenState={
                      data?.cta?.open_in_new_tab == true ? "_blank" : ""
                    }
                  />
                ) : null}
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="right-innerwrapper">
              <img
                src={
                  data?.image?.data?.attributes?.url
                    ? data?.image?.data?.attributes?.url
                    : ""
                }
                className="image"
              />
              {data?.cta?.link ? (
                <Button
                  btnText={data?.cta?.title}
                  btnClass={"learnmorebtn onlyDesktop"}
                  btnLink={data?.cta?.link}
                  target={data?.cta?.open_in_new_tab == true ? "_blank" : ""}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutTheRarest;

import React from "react";

const GinDetailsSection = (props: any) => {
 
  return (
    <div className="gin_section">
      <div className="gin_head">
        <h2>{props?.data?.title}</h2>
        <p>{props?.data?.content}</p>
      </div>
    </div>
  );
};

export default GinDetailsSection;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../common/CommonButton/Button";
import Link from "next/link";

const Kinclaith = (props: any) => {
  const { bottleimgurl, isDisplay, data } = props;
  const kinclaithData = data.items;

  return (
    <>
      <div className="kinclaithtop-heading onlyDesktop">{data?.header}</div>

      {kinclaithData &&
        kinclaithData.length > 0 &&
        kinclaithData.map((item: any, index: any) => {
          return (
            <div className="kinclaith-container" key={index}>
              <Container>
                <Row className={`${index % 2 == 0 ? "" : "flex-row-reverse"}`}>
                  <Col md={6}>
                    <div className="left-innerwrapper">
                      <div className="top-headingtext">{item?.sub_title}</div>
                      <h2 className="headingText">{item?.title}</h2>
                      <p className="description-text">{item?.description}</p>

                      {item?.cta_link ? (
                        <Button
                          btnText={item?.cta_title}
                          btnClass={"btn onlyDesktop"}
                          btnLink={item?.cta_link}
                          linkOpenState={
                            item?.cta_open_in_new_tab == true ? "_blank" : ""
                          }
                        />
                      ) : null}
                      {index % 2 == 0 && (
                        <span className="verical-seperator"></span>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="right-innerwrapper">
                      <img
                        src={
                          item?.bottle_image?.data?.attributes?.url
                            ? item?.bottle_image?.data?.attributes?.url
                            : ""
                        }
                        className="bottleimg"
                      />
                      {/* <img
                        src={"/svg/taylor1969.svg"}
                        className="bottomimg onlyDesktop"
                      /> */}
                      {item?.cta_link ? (
                        <Button
                          btnText={item?.cta_title}
                          btnClass={"btn onlyMobile"}
                          btnLink={item?.cta_link}
                          linkOpenState={
                            item?.cta_open_in_new_tab == true ? "_blank" : ""
                          }
                        />
                      ) : null}
                      {index % 2 != 0 && (
                        <span className="verical-seperator"></span>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        })}
    </>
  );
};

export default Kinclaith;
